body {
    background-color: rgb(204, 204, 204);
    -webkit-print-color-adjust: exact !important;
}


@page {
    size: A4;
    margin: 0 !important;
}

@media print {
    html, body {
        margin: 0 !important;
        width: 210mm;
        height: 297mm;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    .thead th 
    {
        background-color: #dc143c !important; 
    }
    .thead {
        background-color: #dc143c !important; 
        color: white !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    .main-page {
        margin: 0 !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        background-color: white !important;
        width: 100% !important;
        height: 100vb !important;
    }
    .footer-bg {
        background-color: crimson !important;
        position: absolute !important;
        bottom: 0 !important;
       
        color: white !important;
        text-align: center;
    }
   
}
.main-page {
    position: relative;
    width: 210mm;
    min-height: 297mm;
    margin: 10mm auto;
    background: white;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.left-top {
    display: flex;
    flex-direction: column;
    flex: 0.7;
}

.right-top {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.right-top-section {
    display: flex;
    align-items: flex-end;
}

.right-top-section>p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}

.date-picker {
    border: 0 !important;
    width: 77px;
    font-size: 14px;
    margin-left: 35px;
    font-weight: 400 !important;
    align-items: flex-end;
}

.left-top>div>h3 {
    color: crimson;
    font-weight: 500;
    padding: 7px 0;
    margin-left: 15px;
    font-size: 40px;
}

.left-top>p {
    margin-bottom: 0 !important;
    font-weight: 400;
    font-size: 14px;
}

.right-top>h3 {
    color: crimson;
    font-weight: 600;
    text-transform: uppercase;
    padding: 7px 0;
    font-size: 35px;
}

.right-top>p {
    margin-bottom: 0 !important;
    font-weight: 400;
    font-size: 15px;
}

.section1 {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.section1>h5 {
    width: 40%;
    color: white;
    padding: 2px 7px;
    background-color: crimson;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
}

.services-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-plus {
    background-color: green;
    color: white;
    padding: 8px;
    cursor: pointer;
    border-radius: 5px;
}

.delete-btn {
    display: flex;
    align-items: center;
    background-color: crimson;
    color: white;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 400;
    margin-bottom: 1rem;
    justify-content: center;
}

.form-container {
    display: flex;
    gap: 1.5em;
    align-items: center;
}

.submit-btn {
    background-color: crimson;
    outline: none;
    border: 0;
    margin: 1rem 0;
    border-radius: 5px;
    color: white;
    padding: 5px 20px;
    font-weight: 500;
    cursor: pointer;
}

.submit-btn:hover {
    color: white;
}

.thead {
    -webkit-print-color-adjust: exact !important;
    background-color: crimson !important;
    color: white;
}

.subtotal {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
    float: right !important;
    padding: 0 10px;
}

.subtotal>h3 {
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0 5px;
}

.subtotal>h5 {
    font-weight: 600;
    font-size: 20px;
}

.print-btn {
    background-color: crimson;
    outline: none;
    border: 0;
    border-radius: 5px;
    color: white;
    padding: 5px 20px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    right: 50px;
    margin: 50px 0;
}

.table th {
    padding: 1.5px 7px;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
}

.table td {
    padding: 1.5px 7px;
    font-size: 14px;
    text-transform: capitalize;
}

.subtotal {
    display: flex;
    flex-direction: column;
    float: right;
}

.card-body {
    padding: 3px 1.25rem !important;
    align-items: center;
    justify-content: center;
}

.section1>h6 {
    font-weight: 400;
    padding: 2px 7px;
    font-size: 14px;
    margin: 0 !important;
}

.total-text {
    font-weight: 500;
    font-size: 14px;
}

.total-container {
    width: 30%;
}

.hidden {
    opacity: 0;
}

.show {
    visibility: visible;
}

.empty-td {
    padding: 1.5px 7px;
    opacity: 0;
    font-size: 14px;
    text-transform: capitalize;
}

.thank-heading>h4 {
    color: crimson;
    font-weight: 500;
    font-size: 20px;
}

.footer-bg {
    min-width: 100% !important;
    background-color: crimson;
    padding: 1.5px 0;
    position: absolute;
    bottom: 0;
}

.term-condition {
    position: absolute;
    bottom: -250px;
}

.term-condition>p {
    font-weight: 500;
    margin-bottom: 0;
}

.term-condition>h6 {
    font-weight: 700;
}

.footer-bg>div>p {
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.term-signature {
    position: absolute;
    bottom: -280px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.term-signature>h6 {
    margin-top: 5px;
    border-top: 1px solid #333;
    padding: 2px 0;
    font-weight: 700;
}

.term-signature>img {
    height: 70px;
    width: 150px;
}